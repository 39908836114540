/* eslint-disable @typescript-eslint/naming-convention */

const COMMON_ENVIRONMENTS = {
  MARKETING_WEBSITE_URL: 'https://www.qima.com',
  MARKETING_WEBSITE_APP_LINK: '/app-marketing',
  SAMPLING_LEVELS_URL: 'https://www.qima.com/aql-acceptable-quality-limit',
  REFERENCE_SAMPLES: 'https://www.qima.com/reference-samples',
  GOOGLE_MAP_URL: 'https://www.google.com/maps/search/{location}/@39.9375346,115.837023,1z',
  CONDITIONS_URL: 'https://www.qima.com/conditions-of-service',
  TABLEAU_URL: 'https://dashboardspp.qima.com/',
};

export const ENVIRONMENT = {
  dev: {
    production: false,
    SERVER_URL: '/api-dev',
    ...COMMON_ENVIRONMENTS,
    INTERNAL_DOMAINS: ['devmy.qima.com', 'localhost', 'qima.dev'],
  },
  pp: {
    production: false,
    SERVER_URL: '/api-pp',
    ...COMMON_ENVIRONMENTS,
    INTERNAL_DOMAINS: ['preprodmy.qima.com', 'preprodmy.qima.cn', 'localhost', 'qima.dev'],
  },
  staging: {
    production: false,
    SERVER_URL: '/api-staging',
    ...COMMON_ENVIRONMENTS,
    INTERNAL_DOMAINS: ['demomy.qima.com', 'localhost', 'qima.dev'],
  },
  prod: {
    production: true,
    SERVER_URL: '/api-prod',
    ...COMMON_ENVIRONMENTS,
    MARKETING_WEBSITE_APP_LINK: 'https://www.qima.com',
    TABLEAU_URL: 'https://dashboards.qima.com/',
    INTERNAL_DOMAINS: ['my.qima.com', 'my.qima.cn'],
  },
};
