export enum EStorageKeys {
  ADD_SUPPLIER = 'appSupplier',
  ANALYTICS_TABS = 'analyticsTabs',
  AUTH_TOKEN = 'authenticationToken',
  BOOK_DRAFT = 'bookDraftData',
  BOOK_FORM = 'bookFormSession',
  CHECKLIST_FORM_SESSION = 'checklistFormSession',
  CUSTOM_PAYMENT_TOKEN = 'customPaymentTokenKey',
  DRAFT_CHECKLIST = 'draftChecklistData',
  DYNAMIC_BOOKING_MENUS = 'dynamicBookingMenus',
  ENTITY_TYPE = 'entityType',
  IS_BOOKING_FROM_PREVIOUS = 'isBookingFromPrevious',
  LOCALE_LANGUAGE = 'localeLanguage',
  LT_LOCATION_SELECTED = 'ltLocationSelected',
  ORDER_DETAIL_BACK = 'orderDetailBack',
  PRODUCT_CATEGORIES = 'productCategories',
  PRODUCT_TREE = 'productTree',
  PRODUCT_TYPE_WITH_CHECKLIST = 'productTypesWithChecklist',
  REFLOW_MATERIALS = 'reflowMaterials',
  REFORM_FLOW = 'reflowFormSession',
  SELECTED_BUSINESS_SOLUTION = 'selectedBusinessSolution',
  SEND_MOBILE_APP_LINK = 'showMobileBannerCookie',
  SESSION_ID = 'sessionId',
  SHOW_REFLOW_REPORT_DETAIL_SESSION = 'showReflowReportDetailSession',
  SHOW_REPORT_DETAIL_SESSION = 'showReportDetailSession',
  TIME_FILTER = 'timeFilter',
  USER_DATA = 'userData',
  USER_ID = 'userId',
}
