import { DialogRef } from '@aca-new/app/shared/components/modal/shared/classes/dialog.ref';
import { DialogConfirmComponent } from '@aca-new/app/shared/components/modal/shared/components/dialog-confirm/dialog-confirm.component';
import { DialogService } from '@aca-new/app/shared/components/modal/shared/services/dialog.service';
import { IHttpErrorHandler } from '@aca-new/app/shared/interfaces/http-error-handler.interface';
import { EPostMessageType } from '@aca-new/app/shared/models/enums/post-message-type.enum';
import { AcaMessageService } from '@aca-new/app/shared/services/post-message-services/aca-message/aca-message.service';
import { AppAuthenticationService } from '@aca-new/app/shared/services/user-services/app-authentication/app-authentication.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { QimaOptionalType } from '@qima/ngx-qima';

@UntilDestroy()
@Injectable()
export class HttpErrorHandlerService implements IHttpErrorHandler {
  private _dialogRef: QimaOptionalType<DialogRef<unknown>> = undefined;
  private _errorMessage: string = '';

  public constructor(
    private readonly _dialog: DialogService,
    private readonly _appAuthenticationService: AppAuthenticationService,
    private readonly _acaMessageService: AcaMessageService
  ) {}

  public unauthorized(): void {
    this._appAuthenticationService.logout();
  }

  public forbidden(error: Readonly<HttpErrorResponse>): void {
    this._openConfirmModal('ERROR.NO_ACCESS', 'ERROR.NO_ACCESS_MESSAGE')
      .afterClosed$()
      .pipe(untilDestroyed(this))
      .subscribe((isConfirmed): void => {
        if (error.message !== 'DATA_FORBIDDEN' && isConfirmed !== '') {
          this._acaMessageService.postMessageToAca({
            type: EPostMessageType.LOGOUT,
          });
          this._appAuthenticationService.navigateToLogout();
        }
      });
  }

  public internalServerError(error: Readonly<HttpErrorResponse>): void {
    error.message === 'OK' ? this._openConfirmModal('ERROR.PROCESSING', 'ERROR.PROCESSING_MESSAGE') : this._openConfirmModal('ERROR.SERVER_ERROR', 'ERROR.SERVER_ERROR_MESSAGE');
  }

  public notFound(): void {
    return;
  }

  public get errorMessage(): string {
    return this._errorMessage;
  }

  public setErrorMessage(errorMessage: string): void {
    this._errorMessage = errorMessage;
  }

  private _openConfirmModal(title: Readonly<string>, content: Readonly<string>): DialogRef<unknown> {
    if (this._dialogRef) {
      this._dialogRef.close('');
    }

    this._dialogRef = this._dialog.open(DialogConfirmComponent, {
      data: {
        title,
        content,
        confirmButtonLabel: 'ERROR.OK',
        hasCancel: false,
      },
      stopCloseWhenClickBackdrop: true,
    });

    return this._dialogRef;
  }
}
