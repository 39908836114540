import { DialogRef } from '@aca-new/app/shared/components/modal/shared/classes/dialog.ref';
import { DialogCommonModule } from '@aca-new/app/shared/components/modal/shared/components/dialog-common/dialog-common.module';
import { IMAGES } from '@aca-new/app/shared/constants/images.constants';
import { IMyWindow } from '@aca-new/app/shared/interfaces/my-window.interface';
import { QimaButtonService } from '@aca-new/app/shared/services/exported-services/qima-button/qima-button.service';
import { QimaIconService } from '@aca-new/app/shared/services/exported-services/qima-icon/qima-icon.service';
import { WINDOW } from '@aca-new/app/shared/tokens/window.token';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { QimaButtonModule } from '@qima/ngx-qima';

@Component({
  selector: 'app-user-guide',
  templateUrl: './user-guide.component.html',
  styleUrls: ['./user-guide.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [DialogCommonModule, TranslateModule, QimaButtonModule],
})
export class UserGuideComponent<T> {
  public readonly helpGuidePicture: string = IMAGES.helpGuide;
  public readonly plankPicture: string = IMAGES.plank;
  public constructor(
    @Inject(DialogRef) private readonly _dialogRef: DialogRef<T>,
    @Inject(WINDOW) private readonly _window: IMyWindow,
    public readonly qimaButton: QimaButtonService,
    public readonly qimaIcon: QimaIconService
  ) {}

  public onDownloadPdfClick(): void {
    this._window.open('https://static.files.qima.com/legacy/qima/myQIMA_User_Guide_2024.pdf');
  }

  public onCloseClick(): void {
    this._dialogRef.close();
  }
}
